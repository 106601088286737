import React, { useState } from 'react';
import Category from '../components/Categori2'; // Убедитесь, что путь правильный
import ProductList from '../components/ProductList'; // Импорт ProductList
import './Porsche.css';

const Zeekr = () => {
  const items = [
    {
      id: 1,
      title: 'Фары Zeekr',
      imgs: ['zeekr.jpg', 'zeekr1.jpg', 'zeekr2.jpg'],
      desc: 'Описание товара 1',
      category: 'Фонари',
      price: '300000',
    },
    {
      id: 2,
      title: 'Ходовой огонь Zeekr',
      imgs: ['zeekr3.jpg', 'zeekr4.jpg','zeekr5.jpg'],
      desc: 'Описание товара 2',
      category: 'Фары',
      price: '150000',
    },
    {
      id: 3,
      title: 'Фонарь в крыло Zeekr',
      imgs: ['zeekr8.jpg', 'zeekr7.jpg','zeekr7.jpg'],
      desc: 'Описание товара 2',
      category: 'Фары',
      price: '200000',
    },

    
  ];

  const [currentItems, setCurrentItems] = useState(items);

  const chooseCategory = (category) => {
    if (category === 'all') {
      setCurrentItems(items);
    } else {
      setCurrentItems(items.filter((item) => item.category === category));
    }
  };

  return (
    <div>
      <Category chooseCategory={chooseCategory} />
      <ProductList items={currentItems} />
    </div>
  );
};

export default Zeekr;
