import React, { Component } from 'react';

export class ShowFullItem extends Component {
  render() {
    const { item } = this.props;
    return (
      <div className='full-item'>
        <div>
          <img src={'./img/' + item.img} alt={item.title} />
          <h2>{item.title}</h2>
          <p>{item.desc}</p>
          <b>{item.price}</b>
          </div>
      </div>
    );
  }
}

export default ShowFullItem;
