import React, { useState } from 'react';
import Category from '../components/Categori2'; // Убедитесь, что путь правильный
import ProductList from '../components/ProductList'; // Импорт ProductList
import './Porsche.css';

const Hyundai = () => {
  const items = [
    {
      id: 1,
      title: 'Фары Huindai Tucson',
      imgs: ['tuc.jpg', 'tuc2.jpg','tuc3.jpg'],
      desc: '2018-2021',
      category: 'Фары',
      price: '200000',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 2,
      title: 'Фонарь в багажни Huindai Tucson',
      imgs: ['tuc4.jpg'],
      desc: '2018-2021',
      category: 'Фары',
      price: '50000',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
  ];

  const [currentItems, setCurrentItems] = useState(items);

  const chooseCategory = (category) => {
    if (category === 'all') {
      setCurrentItems(items);
    } else {
      setCurrentItems(items.filter((item) => item.category === category));
    }
  };

  return (
    <div>
      <Category chooseCategory={chooseCategory} />
      <ProductList items={currentItems} />
    </div>
  );
};

export default Hyundai;
