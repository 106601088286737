import React, { Component } from 'react';

class Category extends Component {
  constructor(props){
    super(props);
    this.state = {
      categories: [
        {
          key: 'all',
          name: 'Всё',
        },
        {
          key: 'Фары',
          name: 'Фары',
        },
        {
          key: 'Фонари',
          name: 'Фонари',
        },
        {
          key: 'Туманки',
          name: 'Туманки',
        },
        {
          key: 'Поворотники',
          name: 'Поворотники',
        },
      ]
    };
  }

  render() {
    return (
      <div className='categories-container'>
        <div className='categories'>
          {this.state.categories.map(el => (
            <div key={el.key} onClick={() => this.props.chooseCategory(el.key)}>{el.name}</div>
          ))}
        </div>
      </div>
    );
  }
}

export default Category;
