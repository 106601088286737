import React, { useState } from 'react';
import Category from '../components/Categori2'; // Убедитесь, что путь правильный
import ProductList from '../components/ProductList'; // Импорт ProductList
import './Porsche.css';

const Toyota = () => {
  const items = [
    {
      id: 1,
      title: 'Фары Toyota Camry vx70-75',
      imgs: ['camry3.jpg', 'camry2.jpg', 'camry.jpg'],
      desc: '3 полосная фара , черная.',
      category: 'Фары',
      price: '200000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 2,
      title: 'Фары Toyota Land Cruiser 300',
      imgs: ['crusak300.jpg', 'crusak301.jpg', 'crusak302.jpg'],
      desc: 'Описание товара 2',
      category: 'Фары',
      price: '600000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 3,
      title: 'Фары Toyota Rav-4',
      imgs: ['rav43.jpg', 'rav42.jpg', 'rav4.jpg'],
      desc: '1 линза , не под блок 2020 год ',
      category: 'Фары',
      price: '250000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 4,
      title: 'Фары Toyota Rav-4 FULL',
      imgs: ['rav46.jpg', 'rav45.jpg', 'rav44.jpg'],
      desc: '2015-2019 год фулл комплектация EUROPE FULL ',
      category: 'Фары',
      price: '190000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 5,
      title: 'Фары Toytota Land-Cruiser 200',
      imgs: ['crusak202.jpg', 'crusak201.jpg' , 'crusak200.jpg'],
      desc: '2012-2015 год Арабская комплектация',
      category: 'Фары',
      price: '150000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 6,
      title: 'Фары Toyota Land-Cruiser 200',
      imgs: ['crusak205.jpg', 'crusak204.jpg', 'crusak203.jpg'],
      desc: '2016-2021 год ,FULL LED EUROPE комплектация',
      category: 'Фары',
      price: '330000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 7,
      title: 'Фары Toyota Prado 150',
      imgs: ['prado150.jpg', 'prado151.jpg', 'prado152.jpg'],
      desc: '2013-2017 год , объем 2.7 литра  ',
      category: 'Фары',
      price: '120000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 8,
      title: 'Фонарь в крышку багажника Land Cruiser 200',
      imgs: ['LC200.jpg', 'LC201.jpg', 'LC202.jpg'],
      desc: 'Фонарь в крышку багажника Land Cruiser 200 , 2012-2015 год',
      category: 'Фонари',
      price: '50000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 9,
      title: 'Фонарь в крышку багажника  Land Cruiser 200',
      imgs: ['LC203.jpg', 'LC204.jpg', 'LC205.jpg'],
      desc: 'Фонарь в крышку багажника Land Cruiser 200 , 2008-2011 год',
      category: 'Фонари',
      price: '30000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 10,
      title: 'Фонарь в крыло Land Cruiser 200',
      imgs: ['LC206.jpg', 'LC207.jpg', 'LC208.jpg'],
      desc: ' Фонарь в крыло Land Cruiser 200 2016-2021 год',
      category: 'Фонари',
      price: '90000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 11,
      title: 'Фары Toyota Land-Cruiser 200',
      imgs: ['ld.jpg', 'ld2.jpg', 'ld3.jpg'],
      desc: '2012-2015 год , c AFS',
      category: 'Фары',
      price: '250000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 12,
      title: 'Фары Toyota Land-Cruiser 200',
      imgs: ['ld4.jpg', 'ld5.jpg', 'ld6.jpg'],
      desc: '2008-2011 год ,без корректора',
      category: 'Фары',
      price: '120000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 13,
      title: 'Фары Toyota Land-Cruiser 200',
      imgs: ['ld7.jpg', 'ld8.jpg'],
      desc: '2016-2021 год',
      category: 'Фары',
      price: '180000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 14,
      title: 'Фары Toyota Land-Cruiser Prado',
      imgs: ['prado153.jpg', 'prado154.jpg', 'prado155.jpg'],
      desc: '2012-2017 год 4 литра',
      category: 'Фары',
      price: '250000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 15,
      title: 'Фары Toyota Land-Cruiser Prado',
      imgs: ['prado156.jpg', 'prado157.jpg', 'prado158.jpg'],
      desc: '2017 год , 1 - 3 диодные',
      category: 'Фары',
      price: '300000тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 16,
      title: 'Фары Toyota Land-Cruiser Prado 150',
      imgs: ['prado160.jpg', 'prado161.jpg', 'prado162.jpg'],
      desc: 'Prado 150 2.7 литра объем',
      category: 'Фары',
      price: '120000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 17,
      title: 'Фары Toyota Land-Cruiser Prado 150',
      imgs: ['prado170.jpg', 'prado171.jpg'],
      desc: '2010 год 2.7 литра',
      category: 'Фары',
      price: '70000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 18,
      title: 'Фары Toyota Land-Cruiser Prado 120',
      imgs: ['prado180.jpg', 'prado181.jpg'],
      desc: 'с корректором',
      category: 'Фары',
      price: '90000тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 19,
      title: 'Фонарь в крыло  Toyota Land-Cruiser Prado 150',
      imgs: ['pra.jpg', 'pra1.jpg', 'pra2.jpg'],
      desc: '2009-2013 год',
      category: 'Фонари',
      price: '50000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 20,
      title: 'Фонарь в крыло  Toyota Land-Cruiser Prado 150',
      imgs: ['pra3.jpg', 'pra4.jpg', 'pra5.jpg'],
      desc: '2017 год',
      category: 'Фонари',
      price: '90000тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 21,
      title: 'Фонарь в крыло  Toyota Rav 4 ',
      imgs: ['gav.jpg', 'gav2.jpg', 'gav3.jpg'],
      desc: '2020год',
      category: 'Фонари',
      price: '90000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 22,
      title: 'Фонарь в крыло  Toyota Rav 4',
      imgs: ['gav4.jpg'],
      desc: '2010 год',
      category: 'Фонари',
      price: '30000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 23,
      title: 'Фонарь в крышку багажника Toyota Rav 4',
      imgs: ['gav5.jpg', 'gav6.jpg'],
      desc: '2020 год',
      category: 'Фонари',
      price: '60000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 24,
      title: 'Фонарь в крыло  Toyota Land-Cruiser Prado 120',
      imgs: ['prado120.jpg', 'prado121.jpg'],
      desc: '',
      category: 'Фонари',
      price: '40000тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 25,
      title: 'Фонарь в крыло  Toyota camry 40',
      imgs: ['camry40.jpg', 'camry41.jpg', 'camry42.jpg'],
      desc: '',
      category: 'Фонари',
      price: '40000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 26,
      title: 'Фара Toyota Land-Cruiser 100',
      imgs: ['cruser.jpg', 'cruser2.jpg'],
      desc: '1999 год',
      category: 'Фары',
      price: '30000тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 27,
      title: 'Фара Toyota Land-Cruiser 100',
      imgs: ['cruser3.jpg', 'cruser4.jpg'],
      desc: 'Рестайлинг',
      category: 'Фары',
      price: '100000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 28,
      title: 'Фара Toyota Rav 4',
      imgs: ['2020.jpg', '2021.jpg'],
      desc: '2012 год Европа',
      category: 'Фары',
      price: '190000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 29,
      title: 'Фара Toyota Rav 4',
      imgs: ['2022.jpg', '2023.jpg', '2024.jpg'],
      desc: '2020 год под блок',
      category: 'Фары',
      price: '220000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 30,
      title: 'Фара Toyota Highlander ',
      imgs: ['hig.jpg', 'hig2.jpg'],
      desc: '2013-2016 год Галоген',
      category: 'Фары',
      price: '150000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 31,
      title: 'Фара Toyota Highlander',
      imgs: ['hig3.jpg', 'hig4.jpg', 'hig5.jpg'],
      desc: '2016-2019 год',
      category: 'Фары',
      price: '250000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 33,
      title: 'СТОП-СИГНАЛ Toyota Land Cruiser 100 ',
      imgs: ['100.jpg', '101.jpg'],
      desc: '2016-2019 год',
      category: 'СТОП',
      price: '15000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 34,
      title: 'Туманки Toyota 4Runner54',
      imgs: ['4runner.jpg', '4runner2.jpg'],
      desc: '2016-2019 год',
      category: 'Туманки',
      price: '25000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },


    
  ];

  const [currentItems, setCurrentItems] = useState(items);

  const chooseCategory = (category) => {
    if (category === 'all') {
      setCurrentItems(items);
    } else {
      setCurrentItems(items.filter((item) => item.category === category));
    }
  };

  return (
    <div>
      <Category chooseCategory={chooseCategory} />
      <ProductList items={currentItems} />
    </div>
  );
};


export default Toyota;
