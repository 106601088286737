// ProductList.js
import React, { useState } from 'react';
import ProductModal from './ProductModal';
import './ProductList.css';

const ProductList = ({ items }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalIndex, setModalIndex] = useState(0);
  const [currentIndexes, setCurrentIndexes] = useState(
    items.map(() => 0)
  );

  const openModal = (item) => {
    setSelectedItem(item);
    setModalIndex(0);
  };

  const closeModal = () => {
    setSelectedItem(null);
  };

  const handleNext = (itemIndex) => {
    setCurrentIndexes((prevIndexes) =>
      prevIndexes.map((index, i) =>
        i === itemIndex
          ? (index + 1) % items[itemIndex].imgs.length
          : index
      )
    );
  };

  const handlePrev = (itemIndex) => {
    setCurrentIndexes((prevIndexes) =>
      prevIndexes.map((index, i) =>
        i === itemIndex
          ? (index - 1 + items[itemIndex].imgs.length) %
            items[itemIndex].imgs.length
          : index
      )
    );
  };

  const handleModalNext = () => {
    if (selectedItem) {
      setModalIndex((modalIndex + 1) % selectedItem.imgs.length);
    }
  };

  const handleModalPrev = () => {
    if (selectedItem) {
      setModalIndex(
        (modalIndex - 1 + selectedItem.imgs.length) %
        selectedItem.imgs.length
      );
    }
  };

  return (
    <div className="items">
      {items.map((item, itemIndex) => (
        <div key={item.id} className="item">
          <div className="item-imgs">
            <img
              src={`./img/${item.imgs[currentIndexes[itemIndex]]}`}
              alt={item.title}
              className="active"
            />
            <div className="prev" onClick={() => handlePrev(itemIndex)}>
              &#10094;
            </div>
            <div className="next" onClick={() => handleNext(itemIndex)}>
              &#10095;
            </div>
          </div>
          <h2 onClick={() => openModal(item)}>{item.title}</h2>
          <p>{item.desc}</p>
          <b>{item.price}</b>
        </div>
      ))}
      {selectedItem && (
        <ProductModal
          item={selectedItem}
          currentIndex={modalIndex}
          handleNext={handleModalNext}
          handlePrev={handleModalPrev}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default ProductList;
