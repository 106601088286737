import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Item extends Component {
  render() {
    return (
      <div className='item'>
        <div className='img-wrap'>
        <Link to={`/${this.props.item.category.toLowerCase()}`}>
          <img
            src={'./img/' + this.props.item.img}
            alt={this.props.item.title}
          />
        </Link>
        </div>
        <h2>{this.props.item.title}</h2>
        <p>{this.props.item.desc}</p>
        <b>{this.props.item.price}</b>
        <div className='add-to-cart' onClick={() => this.props.onAdd(this.props.item)}>+</div>
      </div>
    );
  }
}

export default Item;
