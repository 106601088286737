import React, { Component } from 'react';
import { FaTrash } from 'react-icons/fa';

export class Orderr extends Component {
  render() {
    const { item, onDelete } = this.props;

    if (!item) {
      return null; // или можно вернуть сообщение об ошибке
    }

    return (
      <div className='item'>
        <img src={'./img/' + item.img} alt={item.title} />
        <h2>{item.title}</h2>
        <b>{item.price}</b>
        <FaTrash className='delete-icon' onClick={() => onDelete(item.id)} />
      </div>
    );
  }
}

export default Orderr;
