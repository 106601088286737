import React, { useState } from 'react';
import Category from '../components/Categori2'; // Убедитесь, что путь правильный
import ProductList from '../components/ProductList'; // Импорт ProductList
import './Porsche.css';

const Mercedes = () => {
  const items = [
    {
      id: 1,
      title: 'Фары Mercedes Benz W222',
      imgs: ['222.jpg', '223.jpg', '224.jpg'],
      desc: 'Рестайлинг',
      category: 'Фары',
      price: '270000',
    },
    {
      id: 2,
      title: 'Фонарь в крыло Mercedes Benz W221',
      imgs: ['1.jpg', '2.jpg'],
      desc: 'Рестайлинг',
      category: 'Фонари',
      price: '80000',
    },
  ];

  const [currentItems, setCurrentItems] = useState(items);

  const chooseCategory = (category) => {
    if (category === 'all') {
      setCurrentItems(items);
    } else {
      setCurrentItems(items.filter((item) => item.category === category));
    }
  };

  return (
    <div>
      <Category chooseCategory={chooseCategory} />
      <ProductList items={currentItems} />
    </div>
  );
};


export default Mercedes;
