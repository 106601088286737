import React from 'react';
import './About.css';
import { FaInstagram, FaTiktok, FaMapMarkerAlt } from 'react-icons/fa';
import { GiCarWheel } from 'react-icons/gi'; // Иконка для MGPARTS и MGPARTS OPTIC

function About() {
  return (
    <div className="about-background">
      <div className="about-container">
        <div className="about-header">
          <h1>О нас</h1>
        </div>
        <div className="about-content">
          <p>
            Мы - команда Mgparts, специализирующаяся на авторазборе и продаже автомобильных запчастей.
            Наш опыт на рынке составляет более 14 лет, за которые мы обслужили более 1000 довольных клиентов.
          </p>
          <div className="about-icons">
            <div className="icon-block">
              <a href="https://www.instagram.com/mgparts.kz/" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} />
                <p>MGPARTS</p>
              </a>
            </div>
            <div className="icon-block">
              <a href="https://www.tiktok.com/@mgparts5" target="_blank" rel="noopener noreferrer">
                <FaTiktok size={30} />
                <p>MGPARTS</p>
              </a>
            </div>
            <div className="icon-block">
              <a href="https://2gis.kz/almaty/search/%D0%B4%D0%BE%D0%BC%D0%B1%D1%80%D0%BE%D0%B2%D1%81%D0%BA%D0%BE%D0%B3%D0%BE%203%5C1/firm/70000001029093396/76.990917%2C43.353396?m=76.988639%2C43.347378%2F15.62" target="_blank" rel="noopener noreferrer"> {/* Замените на реальную ссылку */}
                <GiCarWheel size={30} />
                <p>MGPARTS</p>
              </a>
            </div>
            <div className="icon-block">
              <a href="https://www.instagram.com/mgparts.optic/" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} />
                <p>MGPARTS OPTIC</p>
              </a>
            </div>
            <div className="icon-block">
              <a href="https://www.tiktok.com/@mgpartsoptic" target="_blank" rel="noopener noreferrer">
                <FaTiktok size={30} />
                <p>MGPARTS OPTIC</p>
              </a>
            </div>
            <div className="icon-block">
              <a href="https://2gis.kz/almaty/search/mgparts/firm/70000001089576578/76.986952%2C43.342691?m=76.988639%2C43.342583%2F18.48" target="_blank" rel="noopener noreferrer"> {/* Замените на реальную ссылку */}
                <FaMapMarkerAlt size={30} />
                <p>MGPARTS OPTIC</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
