import React from 'react';
import './Footer.css';
import { FaInstagram, FaTiktok, FaMapMarkerAlt } from 'react-icons/fa';

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-left">
        <h3>Наши социальные сети:</h3>
        <div className="footer-social">
          <a href="https://www.instagram.com/mgparts.kz/" target="_blank" rel="noopener noreferrer">
            <FaInstagram /> Instagram MGPARTS
          </a>
          <a href="https://www.instagram.com/mgparts.optic/" target="_blank" rel="noopener noreferrer">
            <FaInstagram /> Instagram MGPARTS OPTIC
          </a>
          <a href="https://www.tiktok.com/@mgparts5" target="_blank" rel="noopener noreferrer">
            <FaTiktok /> TikTok MGPARTS
          </a>
          <a href="https://www.tiktok.com/@mgpartsoptic" target="_blank" rel="noopener noreferrer">
            <FaTiktok /> TikTok MGPARTS OPTIC
          </a>
        </div>
      </div>
      <div className="footer-center">
        <p>© 2024 Все права защищены</p>
      </div>
      <div className="footer-right">
        <h3>Наши магазины:</h3>
        <div className="footer-stores">
          <p>
            <FaMapMarkerAlt /> Магазин фар - Майлина 73
          </p>
          <p>
            <FaMapMarkerAlt /> Авторазбор - Домбровского 3/1
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
