import React from 'react';
import './Contacts.css';

function Contacts() {
  return (
    <div className="contacts-background">
      <div className="contacts-container">
        <div className="contacts-header">
          <h1>Контакты</h1>
        </div>
        <div className="contact-info">
          <h2>Mgparts Optic</h2>
          <p>
            Адрес: улица Майлина 73<br />
            Контактный телефон: <a href="tel:+77022918915">8-702-291-8915</a><br />
            WhatsApp: <a href="https://wa.me/77022918915" target="_blank" rel="noopener noreferrer" className="whatsapp">WhatsApp</a><br />
            Описание: МGPARTS OPTIC — магазин автомобильной оптики с широким ассортиментом светотехники.
            <br />
            <a href="https://2gis.kz/almaty/gallery/firm/70000001089576578/photoId/9429411764997553?m=76.986847%2C43.342829%2F20" target="_blank" rel="noopener noreferrer">Посмотреть на карте 2ГИС</a>
          </p>
        </div>
        <div className="contact-info">
          <h2>MGPARTS</h2>
          <p>
            Контактный телефон: <a href="tel:+77055556840">8-705-555-6840</a><br />
            WhatsApp: <a href="https://wa.me/77055556840" target="_blank" rel="noopener noreferrer" className="whatsapp">WhatsApp</a><br />
            Адрес: Домбровского 3\1<br />
            Описание: MGPARTS — авторазбор с более чем 14-летним опытом работы и огромным выбором кузовных деталей, двигателей, ходовой части и салона автомобилей.
            <br />
            <a href="https://2gis.kz/almaty/search/%D0%B4%D0%BE%D0%BC%D0%B1%D1%80%D0%BE%D0%B2%D1%81%D0%BA%D0%BE%D0%B3%D0%BE%203%5C1/firm/70000001029093396/76.990917%2C43.353396?m=76.990953%2C43.353412%2F18" target="_blank" rel="noopener noreferrer">Посмотреть на карте 2ГИС</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
