import React, { Component } from 'react';
import Item from './Item';

export class Items extends Component {
  render() {
    const { items } = this.props;

    if (!items || items.length === 0) {
      return <p>No items available</p>;
    }

    return (
      <main>
        {items.map(el => (
          <Item
            onShowItem={this.props.onShowItem}
            key={el.id}
            item={el}
            onAdd={this.props.onAdd}
          />
        ))}
      </main>
    );
  }
}

export default Items;
