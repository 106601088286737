import React, { useState } from 'react';
import Category from '../components/Categori2'; // Убедитесь, что путь правильный
import ProductList from '../components/ProductList'; // Импорт ProductList
import './Porsche.css';

const Bumer = () => {
  const items = [
    {
      id: 1,
      title: 'Фары BMW X5 E70',
      imgs: ['x1.jpg', 'x2.jpg', 'x3.jpg'],
      desc: '2006-2013 год',
      category: 'Фары',
      price: '120000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера. ',
    },
    {
      id: 2,
      title: 'Фары BMW F34 GT',
      imgs: ['x4.jpg', 'x5.jpg', 'x6.jpg'],
      desc: '2011-2016 год',
      category: 'Фары',
      price: '180000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 3,
      title: 'Фары BMW E60',
      imgs: ['x7.jpg', 'x8.jpg'],
      desc: 'Дорест ',
      category: 'Фары',
      price: '60000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 4,
      title: 'Фары BMW F10 ',
      imgs: ['x9.jpg', 'x10.jpg', 'x.jpg'],
      desc: 'ДОРЕСТ ',
      category: 'Фары',
      price: '150000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 5,
      title: 'Фары BMW F07 GT',
      imgs: ['x11.jpg', 'x12.jpg' , 'x13.jpg'],
      desc: '2009-2013 год',
      category: 'Фары',
      price: '190000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 6,
      title: 'Фонари в крыло BMW X5 E70',
      imgs: ['x14.jpg', 'x15.jpg', 'x16.jpg'],
      desc: '2006-2013 год',
      category: 'Фонари',
      price: '40000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 7,
      title: 'Фонари в крыло BMW X6 E71 ',
      imgs: ['x17.jpg', 'x18.jpg'],
      desc: 'Рестайлинг',
      category: 'Фонари',
      price: '80000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 8,
      title: 'Фонари в крыло BMW F10',
      imgs: ['x19.jpg'],
      desc: 'Дорест',
      category: 'Фонари',
      price: '55000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 9,
      title: 'Фонари в крыло BMW G11-G12',
      imgs: ['x20.jpg', 'x21.jpg'],
      desc: '2015 год',
      category: 'Фонари',
      price: '100000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 10,
      title: 'Фонари в крышку багажника G11-G12',
      imgs: ['x22.jpg', 'x23.jpg','x24.jpg'],
      desc: ' 2015 год',
      category: 'Фонари',
      price: '80000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 11,
      title: 'Фонари в крышку багажника BMW F10',
      imgs: ['x25.jpg', 'x26.jpg'],
      desc: 'Дорест',
      category: 'Фонари',
      price: '30000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 12,
      title: 'Фонари в крышку багажника F30 GT',
      imgs: ['x27.jpg', 'x28.jpg'],
      desc: 'GT',
      category: 'Фонари',
      price: '45000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 13,
      title: 'Фонари BMW E60',
      imgs: ['x29.jpg', 'x30.jpg'],
      desc: 'ДОРЕСТ',
      category: 'Фонари',
      price: '40000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 14,
      title: 'Фонари в крыло BMW F34 GT',
      imgs: ['x31.jpg', 'x32.jpg'],
      desc: '2011-2016 год',
      category: 'Фонари',
      price: '80000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 15,
      title: 'Фонари в крыло BMW 5 series GT',
      imgs: ['x33.jpg', 'x34.jpg'],
      desc: '2009-2016 год',
      category: 'Фонари',
      price: '60000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
  ];

  const [currentItems, setCurrentItems] = useState(items);

  const chooseCategory = (category) => {
    if (category === 'all') {
      setCurrentItems(items);
    } else {
      setCurrentItems(items.filter((item) => item.category === category));
    }
  };

  return (
    <div>
      <Category chooseCategory={chooseCategory} />
      <ProductList items={currentItems} />
    </div>
  );
};


export default Bumer;
