import React, { useState } from 'react';
import Category from '../components/Categori2'; // Убедитесь, что путь правильный
import ProductList from '../components/ProductList'; // Импорт ProductList
import './Porsche.css';

const Lexus = () => {
  const items = [
    {
      id: 1,
      title: 'Фары Lexus NX',
      imgs: ['NX.jpg', 'NX2.jpg', 'NX3.jpg'],
      desc: '2014-2017 год',
      category: 'Фары',
      price: '150000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 2,
      title: 'Фары Lexus ux',
      imgs: ['ux.jpg', 'ux2.jpg', 'ux3.jpg'],
      desc: '2019-2020 год Europe',
      category: 'Фары',
      price: '200000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 3,
      title: 'Фары Lexus Rx',
      imgs: ['rx.jpg', 'rx2.jpg', 'rx3.jpg'],
      desc: '2015-2019 год под 1 блок',
      category: 'Фары',
      price: '320000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 4,
      title: 'Фары Lexus LX570',
      imgs: ['lx.jpg', 'lx2.jpg', 'lx3.jpg'],
      desc: '2008-2011 год КСЕНОН',
      category: 'Фары',
      price: '100000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 5,
      title: 'Фары Lexus LX570',
      imgs: ['lx4.jpg', 'lx5.jpg' , 'lx6.jpg'],
      desc: '2012-2015 год',
      category: 'Фары',
      price: '330000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 6,
      title: 'Фары Lexus LX570',
      imgs: ['lx7.jpg', 'lx8.jpg', 'lx9.jpg'],
      desc: '2016-2021 год BLACK VISION 1 блок, 2 блока',
      category: 'Фары',
      price: '950000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 7,
      title: 'Фары Lexus GX460',
      imgs: ['rx4.jpg', 'rx5.jpg', 'rx6.jpg'],
      desc: '2013-2017 год ',
      category: 'Фары',
      price: '350000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 8,
      title: 'Фары Lexus ES',
      imgs: ['ES.jpg', 'ES2.jpg', 'ES3.jpg'],
      desc: '2015-2018 год под блок',
      category: 'Фары',
      price: '250000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 9,
      title: 'Фонарь в крыло Lexus LX570',
      imgs: ['krilo.jpg', 'krilo2.jpg', 'krilo3.jpg'],
      desc: 'Фонарь в крыло Lexus LX570 2008-2011 год',
      category: 'Фонари',
      price: '50000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 10,
      title: 'Фонарь в крышку багажника Lexus LX570',
      imgs: ['bag.jpg', 'bag2.jpg', 'bag3.jpg'],
      desc: ' Фонарь в крышку багажника Lexus LX570 2016-2021 год',
      category: 'Фонари',
      price: '180000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 11,
      title: 'Фонарь в крыло Lexus LX570',
      imgs: ['krilo4.jpg', 'krilo5.jpg', 'krilo6.jpg'],
      desc: ' Фонарь в крыло Lexus LX570 2016-2021 год',
      category: 'Фонари',
      price: '180000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 12,
      title: 'Фонарь в крыло Lexus LX570',
      imgs: ['krilo7.jpg', 'krilo8.jpg', 'krilo9.jpg'],
      desc: ' Фонарь в крыло Lexus LX570  BLACK VISION 2016-2021 год',
      category: 'Фонари',
      price: '250000 тг' ,
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 13,
      title: 'Фонарь в крыло Lexus LX570',
      imgs: ['krilo10.jpg', 'krilo11.jpg', 'krilo12.jpg'],
      desc: ' Фонарь в крыло Lexus LX570 2012-2015 год',
      category: 'Фонари',
      price: '110000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 14,
      title: 'Фара Lexus GX460',
      imgs: ['GX.jpg', 'GX1.jpg', 'GX2.jpg'],
      desc: ' Фара GX460 2010год',
      category: 'Фара',
      price: '150000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 15,
      title: 'Фара Lexus GX470',
      imgs: ['gx3.jpg', 'gx4.jpg', 'gx5.jpg'],
      desc: ' Фара Lexus GX470',
      category: 'Фара',
      price: '140000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 16,
      title: 'Фонарь в крыло Lexus GX460',
      imgs: ['gx6.jpg', 'gx7.jpg', 'gx8.jpg'],
      desc: ' Фонарь в крыло Lexus GX570 Рейсталинг',
      category: 'Фонари',
      price: '180000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 17,
      title: 'Фонарь в крыло Lexus GX460',
      imgs: ['gx9.jpg', 'gx10.jpg', 'gx11.jpg'],
      desc: ' Фонарь в крыло Lexus GX460 ДОРЕСТ',
      category: 'Фонари',
      price: '80000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 18,
      title: 'Фонарь в крыло Lexus GX470',
      imgs: ['gx470.jpg', 'gx471.jpg', 'gx472.jpg'],
      desc: ' Фонарь в крыло Lexus GX470 Рестайлинг',
      category: 'Фонари',
      price: '80000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 19,
      title: 'Фонарь в крыло Lexus GX470',
      imgs: ['gx473.jpg', 'gx474.jpg', 'gx475.jpg'],
      desc: ' Фонарь в крыло Lexus GX470 ДОРЕСТ',
      category: 'Фонари',
      price: '40000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 20,
      title: 'Фонарь в крыло Lexus ES',
      imgs: ['es4.jpg', 'es5.jpg', 'es6.jpg'],
      desc: ' Фонарь в крыло Lexus ES 2018-2021 год',
      category: 'Фонари',
      price: '140000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 21,
      title: 'Фонарь в крышку багажника Lexus ES',
      imgs: ['es7.jpg', 'es8.jpg', 'es9.jpg'],
      desc: ' Фонарь в крышку багажника Lexus ES 2012-2015год',
      category: 'Фонари',
      price: '50000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 22,
      title: 'Фара Lexus LX470 стекло',
      imgs: ['steklo.jpg', 'steklo1.jpg'],
      desc: ' Фара стеклянная LX470 Дорест',
      category: 'Фары',
      price: '100000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 23,
      title: 'Фара Lexus RX',
      imgs: ['rxe.jpg', 'rxe1.jpg', 'rxe2.jpg'],
      desc: 'Фара Lexus Rx 2009-2012 год',
      category: 'Фары',
      price: '150000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 24,
      title: 'Фары  Lexus ES',
      imgs: ['ess.jpg', 'ess2.jpg', 'ess3.jpg'],
      desc: ' Фары Lexus Es 2015-2018 год ',
      category: 'Фары',
      price: '250000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 25,
      title: 'Фары  Lexus ES',
      imgs: ['les.jpg', 'les2.jpg', 'les3.jpg'],
      desc: ' Фары Lexus Es 2018-2022 год',
      category: 'Фары',
      price: '250000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 26,
      title: 'Фары  Lexus ES',
      imgs: ['les4.jpg', 'les5.jpg', 'les6.jpg'],
      desc: ' Фары Lexus Es 2018-2023 год 4 линзы',
      category: 'Фары',
      price: '400000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 27,
      title: 'Фонарь в крышку багажника Lexus RX',
      imgs: ['a.jpg', 'a1.jpg'],
      desc: 'Фонари в крышку багажника Lexus RX 2000-2003 год',
      category: 'Фонари',
      price: '25000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 28,
      title: 'Фонарь в крышку багажника Lexus RX',
      imgs: ['a2.jpg', 'a3.jpg'],
      desc: 'Фонарь в крышку багажника Lexus RX 2019-2022 год',
      category: 'Фонари',
      price: '150000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 29,
      title: 'Фонари в крыло Lexus RX',
      imgs: ['a4.jpg', 'a5.jpg'],
      desc: 'Фонари задний в крыло Lexus RX 2015-2019 год',
      category: 'Фонари',
      price: '160000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 30,
      title: 'Фонари в крышку багажника Lexus ES',
      imgs: ['a6.jpg', 'a7.jpg'],
      desc: ' Фонари в крышку багажника Lexus ES 2018-2021 год',
      category: 'Фонари',
      price: '90000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 31,
      title: 'Фонарь в крышку багажника Lexus Rx',
      imgs: ['a8.jpg', 'a9.jpg', 'a10.jpg'],
      desc: 'Фонарь в крышку багажника Lexus Rx 2015-2019 год',
      category: 'Фонари',
      price: '130000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 32,
      title: 'Фонарь в крышку багажника Lexus Rx',
      imgs: ['a11.jpg', 'a12.jpg'],
      desc: 'Фонарь в крышку багажника Lexus Rx 2003-2006 год',
      category: 'Фонари',
      price: '40000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 33,
      title: 'Фонарь в крыло Lexus Ls',
      imgs: ['a13.jpg', 'a14.jpg', 'a15.jpg'],
      desc: 'Фонарь в крыло Lexus Ls 2009-2012 год',
      category: 'Фонари',
      price: '50000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 34,
      title: 'Фонарь в крышку багажника Lexus LS',
      imgs: ['a16.jpg', 'a17.jpg'],
      desc: 'Фонарь в крышку багажника Lexus Ls 2009-2012 год',
      category: 'Фонари',
      price: '50000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
  ];

  const [currentItems, setCurrentItems] = useState(items);

  const chooseCategory = (category) => {
    if (category === 'all') {
      setCurrentItems(items);
    } else {
      setCurrentItems(items.filter((item) => item.category === category));
    }
  };

  return (
    <div>
      <Category chooseCategory={chooseCategory} />
      <ProductList items={currentItems} />
    </div>
  );
};


export default Lexus;
