import React, { useState } from 'react';
import Category from '../components/Categori2'; // Убедитесь, что путь правильный
import ProductList from '../components/ProductList'; // Импорт ProductList
import './Porsche.css';

const Nissan = () => {
  const items = [
    {
      id: 1,
      title: 'Фары Nissan Patrol',
      imgs: ['n.jpg', 'n2.jpg', 'n3.jpg'],
      desc: '2010-2014 год',
      category: 'Фары',
      price: '60000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 2,
      title: 'Фонарь в крышку багажника Nissan Patrol  ',
      imgs: ['q.jpg', 'q1.jpg'],
      desc: '2010-2014 год',
      category: 'Фонари',
      price: '20000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 3,
      title: 'Фонари в крышку багажника Nissan Patrol',
      imgs: ['q2.jpg', 'q3.jpg'  ],
      desc: '2014-2019 год',
      category: 'Фонари',
      price: '40000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 4,
      title: 'Фонари в крыло Nissan Patrol',
      imgs: ['q4.jpg', 'q5.jpg'],
      desc: '2014-2019  год ',
      category: 'Фонари',
      price: '60000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 5,
      title: 'Фонарь в крыло Nissan Patrol ',
      imgs: ['q6.jpg', 'q7.jpg'],
      desc: '2010-2014год ',
      category: 'Фонари',
      price: '40000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
  ];

  const [currentItems, setCurrentItems] = useState(items);

  const chooseCategory = (category) => {
    if (category === 'all') {
      setCurrentItems(items);
    } else {
      setCurrentItems(items.filter((item) => item.category === category));
    }
  };

  return (
    <div>
      <Category chooseCategory={chooseCategory} />
      <ProductList items={currentItems} />
    </div>
  );
};

export default Nissan;
