import React from 'react';
import './ProductModal.css';

const ProductModal = ({ item, currentIndex, handleNext, handlePrev, onClose }) => {
  const whatsappLink = `https://wa.me/77022918915?text=${encodeURIComponent(`Я заинтересован в покупке ${item.title} за ${item.price}`)}`;

  const handleClickOutside = (e) => {
    if (e.target.className === 'modal') {
      onClose();
    }
  };

  return (
    <div className="modal" onClick={handleClickOutside}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <div className="modal-images">
          <img
            src={`./img/${item.imgs[currentIndex]}`}
            alt={item.title}
            className="active"
          />
          <div className="prev" onClick={handlePrev}>
            &#10094;
          </div>
          <div className="next" onClick={handleNext}>
            &#10095;
          </div>
        </div>
        <div className="modal-details">
          <div className="description">
            <h2>{item.title}</h2>
            <p>{item.fullDesc}</p> {/* Отображаем новое описание */}
            <b>{item.price}</b>
          </div>
        </div>
        <div className="buttons">
          <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
            <button>Купить</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
