import React, { useState } from 'react';
import Category from '../components/Categori2'; // Убедитесь, что путь правильный
import ProductList from '../components/ProductList'; // Импорт ProductList
import './Porsche.css';

const LandRover = () => {
  const items = [
    {
      id: 1,
      title: 'Фары Range Rover Vellar',
      imgs: ['vel.jpg', 'vel2.jpg', 'vel3.jpg','vel4.jpg'],
      desc: '2017 год',
      category: 'Фары',
      price: '540000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 2,
      title: 'Фары Range Rover L494 Sport 2',
      imgs: ['l.jpg', 'l2.jpg', 'l3.jpg'],
      desc: '2017 - Новый год',
      category: 'Фары',
      price: '650000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 3,
      title: 'Фары Range Rover L405',
      imgs: ['l405.jpg', 'l406.jpg', 'l407.jpg'],
      desc: '2015 год ',
      category: 'Фары',
      price: '250000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 4,
      title: 'Фары Land Rover Discovery sport',
      imgs: ['ec.jpg', 'ec2.jpg', 'ec3.jpg'],
      desc: 'EC FULL 2020 год ',
      category: 'Фары',
      price: '400000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 5,
      title: 'Фары Range Rover L405',
      imgs: ['l4.jpg', 'l5.jpg' , 'l6.jpg'],
      desc: '2017-2022 год FULL',
      category: 'Фары',
      price: '500000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 6,
      title: 'Фары Range Rover Sport L494',
      imgs: ['l7.jpg', 'l8.jpg'],
      desc: '2013-2017 год',
      category: 'Фары',
      price: '200000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 7,
      title: 'Фары Range Rover Evoque L538',
      imgs: ['l9.jpg', 'l10.jpg'],
      desc: '2015-2018 год ',
      category: 'Фары',
      price: '200000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 8,
      title: 'Фары Range Rover Evoque L538',
      imgs: ['l11.jpg', 'l12.jpg'],
      desc: '2011 год',
      category: 'Фары',
      price: '220000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 9,
      title: 'Фары Range Rover Discovery Sport ',
      imgs: ['l13.jpg', 'l14.jpg'],
      desc: '2014-2019 год',
      category: 'Фары',
      price: '250000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 10,
      title: 'Фары Range Rover L405',
      imgs: ['l15.jpg', 'l16.jpg'],
      desc: ' 2015 год',
      category: 'Фары',
      price: '250000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 11,
      title: 'Фары Range Rover Discovery 5',
      imgs: ['l17.jpg', 'l18.jpg'],
      desc: '2024 год',
      category: 'Фары',
      price: '450000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 12,
      title: 'Фары Range Rover Sport 2',
      imgs: ['l19.jpg', 'l20.jpg', 'l21.jpg'],
      desc: '2017-2023 год ',
      category: 'Фары',
      price: '600000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 13,
      title: 'Фары Range Rover Discovery ',
      imgs: ['l22.jpg', 'l23.jpg', 'l24.jpg'],
      desc: '2009-2013 год',
      category: 'Фары',
      price: '160000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 14,
      title: 'Фары Range Rover Discovery L319',
      imgs: ['l25.jpg', 'l26.jpg', 'l27.jpg'],
      desc: '2004-2009 год',
      category: 'Фары',
      price: '110000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 15,
      title: 'Фары Range Rover Discovery ',
      imgs: ['l28.jpg', 'l29.jpg', 'l30.jpg'],
      desc: '2013-2016 год ',
      category: 'Фары',
      price: '250000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 16,
      title: 'Фары Range Rover L405',
      imgs: ['l31.jpg', 'l32.jpg'],
      desc: '2017-2021 год ',
      category: 'Фары',
      price: '500000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 17,
      title: 'Фары Range Rover Voque ',
      imgs: ['l33.jpg', 'l34.jpg'],
      desc: '2017 год',
      category: 'Фары',
      price: '500000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 18,
      title: 'Фонарь Range Rover L322',
      imgs: ['l35.jpg', 'l36.jpg'],
      desc: '2007 год красный',
      category: 'Фонари',
      price: '60000 тг ',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 19,
      title: 'Фонарь Range Rover L322',
      imgs: ['l37.jpg', 'l38.jpg'],
      desc: 'Рестайлинг',
      category: 'Фонари',
      price: '100000 тг ',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 20,
      title: 'Фонарь Range Rover L322',
      imgs: ['l39.jpg', 'l40.jpg'],
      desc: '2007 год белый',
      category: 'Фонари',
      price: '80000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 21,
      title: 'Фара RR Sport L320',
      imgs: ['l41.jpg', 'l42.jpg','l43.jpg' , 'l45.jpg'],
      desc: 'Рестайлинг 2005-2012',
      category: 'Фары',
      price: '230000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 22,
      title: 'Фара RR Sport L320',
      imgs: ['l46.jpg', 'l47.jpg','l48.jpg'],
      desc: 'Рестайлинг 2009-2012',
      category: 'Фары',
      price: '230000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 23,
      title: 'Фара RR Defender',
      imgs: ['l49.jpg', 'l50.jpg','l51.jpg'],
      desc: '2020 год',
      category: 'Фары',
      price: '450000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 24,
      title: 'Фонарь Range Rover Vellar',
      imgs: ['k1.jpg', 'k2.jpg'],
      desc: '2018 ',
      category: 'Фонари',
      price: '470000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 25,
      title: 'Фонарь в крыло Range Rover Evoque',
      imgs: ['k3.jpg', 'k4.jpg'],
      desc: '2018-2020 ',
      category: 'Фонари',
      price: '130000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 26,
      title: 'Фонарь Range Rover L322',
      imgs: ['k5.jpg', 'k6.jpg'],
      desc: '2009-2013 ',
      category: 'Фонари',
      price: '100000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 27,
      title: 'Фонарь Range Rover Discovery',
      imgs: ['k7.jpg', 'k8.jpg'],
      desc: '2004-2009 ',
      category: 'Фонари',
      price: '50000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 28,
      title: 'Фонарь Range Rover L405',
      imgs: ['k9.jpg', 'k0.jpg'],
      desc: '2015 ',
      category: 'Фонари',
      price: '150000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 29,
      title: 'Фонарь Range Rover L405',
      imgs: ['k11.jpg', 'k12.jpg'],
      desc: '2017-2022 ',
      category: 'Фонари',
      price: '200000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 30,
      title: 'Фонарь Range Rover L494',
      imgs: ['k13.jpg', 'k14.jpg'],
      desc: '2013-2017 ',
      category: 'Фонари',
      price: '120000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 31,
      title: 'Фонарь в крыло  Range Rover Discovery Sport',
      imgs: ['k15.jpg', 'k16.jpg'],
      desc: '2018 ',
      category: 'Фонари',
      price: '150000',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 32,
      title: 'Фонарь Range Rover Evoque',
      imgs: ['k17.jpg'],
      desc: '2012-2015 ',
      category: 'Фонари',
      price: '150000',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 33,
      title: 'Фонарь крышки багажника Range Rover Evoque L551',
      imgs: ['k18.jpg', 'k19.jpg'],
      desc: '2018 ',
      category: 'Фонари',
      price: '80000',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
   

    
  ];

  const [currentItems, setCurrentItems] = useState(items);

  const chooseCategory = (category) => {
    if (category === 'all') {
      setCurrentItems(items);
    } else {
      setCurrentItems(items.filter((item) => item.category === category));
    }
  };

  return (
    <div>
      <Category chooseCategory={chooseCategory} />
      <ProductList items={currentItems} />
    </div>
  );
};


export default LandRover;
