import React, { useState, useEffect } from 'react';
import { FaShoppingBasket } from 'react-icons/fa';
import Orderr from './Orderr';
import { Link } from 'react-router-dom';
import presentationImage from '../img/avtomobil-fary-1.png'; // Перейдите на один уровень вверх из components в src

export default function Header(props) {
  const [cartOpen, setCartOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // Для вставки скрипта Yandex.Metrika
  useEffect(() => {
    // Создание скрипта и вставка его в DOM
    const script = document.createElement('script');
    script.src = 'https://mc.yandex.ru/metrika/tag.js';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      if (window.ym) {
        window.ym(98238278, 'init', {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
        });
      }
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const showOrders = () => {
    let summa = 0;
    props.orders.forEach(el => summa += Number.parseFloat(el.price));
    return (
      <div>
        {props.orders.map(el => (
          <Orderr onDelete={props.onDelete} key={el.id} item={el} />
        ))}
        <p className='summa'>
          Сумма: {new Intl.NumberFormat().format(summa)}$
        </p>
      </div>
    );
  }

  const showNothing = () => {
    return (
      <div className='empty'>
        <h2>Корзина пуста</h2>
      </div>
    );
  }

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    // Здесь вы можете обработать поиск
    console.log(`Поиск: ${searchQuery}`);
  }

  return (
    <header>
      <div>
        <span className='logo'>
          <p>Магазин Автомобильной оптики</p>
        </span>

        <ul className='Nav'>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/contacts">Контакты</Link></li>
          <li><Link to="/about">О нас</Link></li>
        </ul>

        <form className='search-form' onSubmit={handleSearchSubmit}>
          <input 
            type="text" 
            placeholder="Поиск по сайту..." 
            value={searchQuery} 
            onChange={handleSearchChange} 
            className='search-bar'
          />
          <button type="submit">Поиск</button>
        </form>

        <FaShoppingBasket 
          onClick={() => setCartOpen(!cartOpen)} 
          className={`shop-cart-button ${cartOpen ? 'active' : ''}`}
        />

        {cartOpen && (
          <div className='shop-cart'>
            {props.orders.length > 0 ? showOrders() : showNothing()}
          </div>
        )}

        <Link to="/" className="presentation">
          <img src={presentationImage} alt="Презентация" />
        </Link>
      </div>
    </header>
  );
}
