import React, { useState } from 'react';
import Category from '../components/Categori2'; // Убедитесь, что путь правильный
import ProductList from '../components/ProductList'; // Импорт ProductList
import './Porsche.css';

const Porshe = () => {
  const items = [
    {
      id: 1,
      title: 'Фары Porsche Panamera ',
      imgs: ['panamera.jpg', 'panamera2.jpg', 'panamera3.jpg'],
      desc: '2011 год',
      category: 'Фары',
      price: '500000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 2,
      title: 'Фары Porsche 970',
      imgs: ['958.jpg', '9581.jpg'  ],
      desc: '2012-2015 год',
      category: 'Фары',
      price: '250000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 3,
      title: 'Фары Porsche Panamera 970 GTS FULL',
      imgs: ['panamera4.jpg', 'panamera5.jpg', 'panamera6.jpg'],
      desc: '2013-2016  год ',
      category: 'Фары',
      price: '550000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 4,
      title: 'Фары Porsche Caen ',
      imgs: ['caen.jpg', 'caen2.jpg'],
      desc: '2011-2018год ',
      category: 'Фары',
      price: '600000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 5,
      title: 'Фары Porsche Panamera ',
      imgs: ['palma.jpg', 'palma2.jpg' , 'palma3.jpg','palma4.jpg'],
      desc: '2010-2018 год',
      category: 'Фары',
      price: '500000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 6,
      title: 'Фары Porsche Caen ',
      imgs: ['palma5.jpg', 'palma6.jpg','palma7.jpg'],
      desc: '2018 год Full Led',
      category: 'Фары',
      price: '700000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 7,
      title: 'Фары Porsche Panamera ',
      imgs: ['plm.jpg', 'plm2.jpg','plm3.jpg'],
      desc: '2014-2016 год ',
      category: 'Фары',
      price: '350000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 8,
      title: 'Фары Porsche Caen',
      imgs: ['ca.jpg', 'ca2.jpg','ca3.jpg'],
      desc: '2010-2017 год',
      category: 'Фары',
      price: '185000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 9,
      title: 'Фары Porsche Macan 95b',
      imgs: ['macan.jpg', 'macan2.jpg','macan3.jpg'],
      desc: 'Рестайлинг',
      category: 'Фары',
      price: '400000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 10,
      title: 'Фары Porsche Caen 958',
      imgs: ['plm4.jpg', 'plm5.jpg','plm6.jpg','plm7.jpg'],
      desc: ' 2015 год',
      category: 'Фары',
      price: '700000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    {
      id: 11,
      title: 'Фары Porsche Caen',
      imgs: ['plm10.jpg', 'plm11.jpg','plm12.jpg'],
      desc: '2010-2017  год',
      category: 'Фары',
      price: '185000 тг',
      fullDesc:'Гарантия и условия возврата: Уточните у менеджера.',
    },
    
    

    
  ];

  const [currentItems, setCurrentItems] = useState(items);

  const chooseCategory = (category) => {
    if (category === 'all') {
      setCurrentItems(items);
    } else {
      setCurrentItems(items.filter((item) => item.category === category));
    }
  };

  return (
    <div>
      <Category chooseCategory={chooseCategory} />
      <ProductList items={currentItems} />
    </div>
  );
};

export default Porshe;