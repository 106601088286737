import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Footer from './components/Footer.js';
import Header from './components/header.js';
import Items from './components/items.js';
import Categories from './components/Categories.js';
import ShowFullItem from './components/ShowFullItem.js';
import Home from '../src/pages/Home.js';
import About from '../src/pages/About';
import Contacts from '../src/pages/Contacts';
import LandRover from '../src/pages/LandRover';
import Lexus from '../src/pages/Lexus';
import Porshe from './pages/Porshe.js';
import Toyota from '../src/pages/Toyota';
import Zeekr from '../src/pages/Zeekr';
import Nissan from '../src/pages/Nissan.js';
import Hyundai from './pages/Hyundai.js';
import Volkswagen from './pages/Volkswagen.js';
import Infiniti from './pages/Infiniti.js'
import Bumer from './pages/Bumer';
import Mersedec from './pages/Mersedec';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      currentItems: [],
      items: [
        {
          id: 1,
          title: 'Товары Porshe',
          img: 'porshe.png',
          desc: 'Фонари , Фары, туманки , от Porshe',
          category: 'Porshe',
        },
        {
          id: 2,
          title: 'Товары Lexus',
          img: 'lexus.webp',
          desc: 'Фонари, Фары, туманки от Lexus',
          category: 'Lexus',
        },
        {
          id: 3,
          title: 'Товары Land Rover',
          img: 'Land_Rover.svg.png',
          desc: 'Фонари , фары, туманки от Land Rover',
          category: 'LandRover',
        },
        {
          id: 4,
          title: 'Товары Toyota',
          img: 'toyota.png',
          desc: 'Фонари , фарф, туманки от Toyota',
          category: 'Toyota',
        },
        {
          id: 5,
          title: 'Товары Zeekr',
          img: 'Zeekr-logo.png',
          desc: 'Фонари , фары, от Zeekr',
          category: 'Zeekr',
        },
        {
          id: 6,
          title: 'Товары Nissan',
          img: 'polo.png',
          desc: 'Фонари , фары, от Nissan',
          category: 'Nissan',
        },
        {
          id: 7,
          title: 'Товары Infiniti',
          img: 'infiniti.png',
          desc: 'Фонари, фары, от Infiniti',
          category: 'Infiniti',
        },
        {
          id: 8,
          title: 'Товары Volkswagen',
          img: 'polo.webp',
          desc: 'Фонари, фары, от Volkswagen',
          category: 'Volkswagen',
        },
        {
          id: 9,
          title: 'Товары Hyundai',
          img: 'h.png',
          desc: 'Фонари, фары, от Hyundai',
          category: 'Hyundai',
        },
        {
          id: 10,
          title: 'Товары Mercedes-Benz',
          img: 'mers.png',
          desc: 'Фонари, фары, от Mercedes-Benz',
          category: 'Mersedec',
        },
        {
          id: 11,
          title: 'Товары BMW',
          img: 'bmw.webp',
          desc: 'Фонари, фары, от Hyundai',
          category: 'Bumer',
        },
        {
          id:12,
          title: 'Товары Kia',
          img:'kia_logo.png',
          desc:'KIA',
          category:'KIA',

        },
      ],
      ShowFullItem: false,
      fullItem: {},
    };
    this.state.currentItems = this.state.items;
    this.addToOrder = this.addToOrder.bind(this);
    this.deleteOrder = this.deleteOrder.bind(this);
    this.chooseCategory = this.chooseCategory.bind(this);
    this.onShowItem = this.onShowItem.bind(this);
  }

  render() {
    return (
      <Router>
        <Content 
          orders={this.state.orders}
          currentItems={this.state.currentItems}
          items={this.state.items}
          ShowFullItem={this.state.ShowFullItem}
          fullItem={this.state.fullItem}
          addToOrder={this.addToOrder}
          deleteOrder={this.deleteOrder}
          chooseCategory={this.chooseCategory}
          onShowItem={this.onShowItem}
        />
      </Router>
    );
  }

  onShowItem(item) {
    this.setState({
      ShowFullItem: !this.state.ShowFullItem,
      fullItem: item,
    });
  }

  chooseCategory(category) {
    if (category === 'all') {
      this.setState({ currentItems: this.state.items });
      return;
    }
    this.setState({
      currentItems: this.state.items.filter(el => el.category === category),
    });
  }

  deleteOrder(id) {
    this.setState({ orders: this.state.orders.filter(el => el.id !== id) });
  }

  addToOrder(item) {
    let isInArray = false;
    this.state.orders.forEach(el => {
      if (el.id === item.id) {
        isInArray = true;
      }
    });

    if (!isInArray) {
      this.setState({ orders: [...this.state.orders, item] });
    }
  }
}

function Content(props) {
  const location = useLocation();
  return (
    <div className="wrapper">
      <Header orders={props.orders} onDelete={props.deleteOrder} />
      <Routes>
        <Route 
          path="/" 
          element={
            <>
              <Categories chooseCategory={props.chooseCategory} />
              <Items onShowItem={props.onShowItem} items={props.currentItems} onAdd={props.addToOrder} />
            </>
          } 
        />
        <Route path="/about" element={<About />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/landrover" element={<LandRover />} />
        <Route path="/lexus" element={<Lexus />} />
        <Route path="/porshe" element={<Porshe />} />
        <Route path="/toyota" element={<Toyota />} />
        <Route path="/zeekr" element={<Zeekr />} />
        <Route path="/nissan" element={<Nissan/>} />
        <Route path="/hyundai" element={<Hyundai/>} />
        <Route path="/volkswagen" element={<Volkswagen/>} />
        <Route path="/infiniti" element={<Infiniti/>} />
        <Route path="/bumer" element={<Bumer/>} />
        <Route path="/mersedec" element={<Mersedec/>} />
      </Routes>
      {props.ShowFullItem && (
        <ShowFullItem 
          onShowItem={props.onShowItem} 
          onAdd={props.addToOrder} 
          item={props.fullItem} 
        />
      )}
      <Footer />
    </div>
  );
}

export default App;
